
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, .7);
  z-index: 9998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal {
    max-height: 90%;
    overflow: hidden;
    width: 100%;
    max-width: 650px;
    padding: 20px 20px 40px;
    background: #252525;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.5);

    position: relative;
    
    .close {
      position: absolute;
      top: 10px;
      right: 4px;
      cursor: pointer;
      opacity: .5;
      width: 20px;
      height: 20px;
      
      &:before {
        content: "X";
        font-family: Roboto, sans-serif;
        font-size: 17px;
        font-weight: 700;
      }
    }

    .body {
      height: 100%;
    }
  }
}