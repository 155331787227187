$width: 712px;
$padding: 40px;

.page.threads {
  .inner {
    width: 100%;
    max-width: $width + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;

    @media only screen and (max-width: 420px) {
      padding: 0 calc($padding / 2);
    }

    .addThread {
      margin: 0 auto;
      background: #2194bb;
      border-radius: 3px;
      color: #fff;
      font-family: 'Rubik', sans-serif;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 6px 32px;
      display: block;
      cursor: pointer;
    }

    .threads {
      display: flex;
      gap: 16px;
      flex-direction: column;
      margin-top: 32px;
    }

    .thread {
      background: #353535;
      box-shadow: 0 25px 34px 0 rgba(0,0,0,0.1);
      border-radius: 4px;
      padding: 20px;
      cursor: pointer;

      &.highlight {
        box-shadow: inset 0 0 0 2px #ffffff;
      }
    }

    .thread h2 {
      font-weight: 500;
      color: #eaeaea;
    }

    .thread .last-message {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      margin-top: 5px;
    }

    .thread .footer {
      margin-top: 10px;
    }
  }

  .questionForm {
    h1 {
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .form {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;

      textarea {
        width: 100%;
        background: #434343;
        border-radius: 3px 0 0 3px;
        padding: 12px 14px;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        color: rgba(255, 255, 255, .5);
        resize: none;
      }

      button {
        margin: 25px auto 0;
        background: #2194bb;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 16px 65px 13px;
        display: block;
        cursor: pointer;
      }
    }
  }
}