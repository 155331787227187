$width: 1210px;
$padding: 40px;

.page.scoreboard {
  .inner {
    width: 100%;
    max-width: $width + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;
    align-items: center;

    @media only screen and (max-width: 420px) {
      padding: 0 ($padding / 2);
    }

    .chart-container {
      display: flex;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;

      .chart {
        width: 0;
        flex: 1;
        height: 260px;
      }
      .score-chart-legend {
        display: flex;
        flex-direction: column;
      }
      .score-chart-legend > div {
        display: flex;
        align-items: center;

        .box {
          width: 8px;
          height: 8px;
          margin-right: 6px;
        }
      }

      @media only screen and (max-width: 720px) {
        flex-direction: column;

        .chart {
          width: 100%;
          align-self: stretch;
          flex: initial;
        }
        .score-chart-legend {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
    }

    h4 {
      color: #3aadd4;
      font-size: 17px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
      margin-top: -20px;
    }

    .unfreeze {
      color: grey;
    }

    .time{
      transform: scale(0.8);
      margin-bottom: 20px;
    }

    .time > div:not(.spacer) {
      font-size: 30px !important;
    }
    .time .spacer {
      font-size: 30px !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    @media only screen and (max-width: 420px) {
      padding: 0 $padding / 2;
    }

    .table {
      width: 100%;
      overflow: auto;
      background-color: #1d1d1d;
      opacity: 0.8;

      table {
        border-collapse: collapse;

        thead {
          tr {
            &:nth-child(1) {
              th {
                padding: 8px 0;
                font-size: 13px;
                font-weight: 400;

                > div {
                  margin-left: -50%;
                }
              }
            }
            &:nth-child(2) {
              th {
                height: 80px;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                position: relative;
                vertical-align: bottom;
                padding: 0;
                line-height: 0.8;
                font-size: 10px;
                font-weight: 400;
                color: #eaeaea;

                &.light {
                  > div {
                    border-left-color: #eaeaea;
                  }
                }

                &:last-child {
                  > div {
                    border-right: 1px solid #535353;
                  }
                }

                > div {
                  position: relative;
                  top: 0;
                  left: -40px;
                  height: 100%;
                  transform: skew(45deg, 0deg);
                  overflow: hidden;
                  border-top: 1px solid #535353;
                  border-left: 1px solid #535353;

                  span {
                    transform: skew(-45deg, 0deg) rotate(45deg);
                    position: absolute;
                    bottom: 35px;
                    left: -25px;
                    display: inline-block;
                    width: 85px;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
            &:nth-child(3) {
              th {
                border-top: 1px solid #8e8e8e;
                border-bottom: 1px solid #8e8e8e;
                border-left: 1px solid #535353;
                text-align: center;
                font-size: 11px;
                font-weight: 500;
                color: #3aadd4;
                padding: 4px 8.5px;

                &.light {
                  border-left-color: #8e8e8e;
                }

                &:nth-child(1) {
                  width: 45px;
                  border-left: none;
                }

                &:nth-child(3) {
                  width: 60px;
                }

                &:nth-child(-n+4) {
                  font-size: 10px;
                  color: #eaeaea;
                }

                &:nth-child(4) {
                  width: 53px;
                }

                &:nth-child(5n) {
                  width: 40px;
                }

                &:last-child {
                  border-right: 1px solid #535353;
                }
              }

            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 11px;
              color: #eaeaea;
              text-align: center;
              border-left: 1px solid #535353;
              border-bottom: 1px solid #535353;
              height: 30px;
              padding: 7px 10px;

              img:not(.avatar) {
                max-width: 15px;
                max-height: 20px;
              }

              .avatar {
                max-height: 35px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
              }

              &.left {
                text-align: left;
              }

              &.light {
                border-left-color: #8e8e8e;
              }

              &:first-child {
                border-left: none;
              }

              &:last-child {
                border-right: 1px solid #535353;
              }

              &:nth-child(2) {
                min-width: 200px;
              }
            }
          }
        }
      }
    }

    .more {
      cursor: pointer;
      padding: 16px 48px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      border: 2px solid #3aadd4;
      border-radius: 3px;
      margin-top: 40px;
    }
  }
}
