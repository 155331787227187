$width: 712px;
$padding: 40px;

.page.threadview {
  .inner {
    width: 100%;
    max-width: $width + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;

    @media only screen and (max-width: 420px) {
      padding: 0 ($padding / 2);
    }

    .top {
      display: flex;
      align-items: center;

      .title {
        flex: 1;
        text-align: center;
      }
      .title-pad {
        width: 100px;
      }
      .go-back a {
        background: #2194bb;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        display: inline-block;
        cursor: pointer;
      }

      .close button {
        background: #cb3434;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        display: inline-block;
        cursor: pointer;
      }

      .closed button {
        background: #303030;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        display: inline-block;
        cursor: pointer;
      }
    }

    .messages {
      overflow: hidden;

      .message {
        padding: 10px;
        background: transparent;

        &.from-orgs {
          background: rgba( 0, 0, 0, .2);
        }
      }

      h5 {
        font-size: 14px;
        margin-bottom: 7px;
      }

      .body {
        font-size: 15px;
      }
    }

    .form {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: row;

      textarea {
        width: 100%;
        background: #434343;
        border-radius: 3px 0 0 3px;
        padding: 12px 14px;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        color: rgba(255, 255, 255, .5);
        resize: none;
        display: inline-block;
        flex: 0.9;
      }

      button {
        // margin: 25px auto 0;
        background: #2194bb;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        // padding: 16px 65px 13px;
        cursor: pointer;
        display: inline-block;
        flex: 0.1;
      }
    }

    .threadFooter {
      flex-shrink: 0;
      margin-top: auto;
    }
  }
}